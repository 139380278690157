import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Login';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Registro from './Registro'
import Home from './Home'
import Prehome from './Prehome'
import Trabajadores from './Trabajadores'

function App() {
  return (
   
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />}></Route>
        <Route path='/registro' element={<Registro />}></Route>
        <Route path='/home' element={<Home />}></Route>
        <Route path='/Prehome' element={<Prehome />}></Route>
        <Route path='/Trabajadores' element={<Trabajadores />}></Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
