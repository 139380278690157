import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';

const App = () => {
  const [selectedCard, setSelectedCard] = React.useState(null);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['selectedCard']);

  const handleSelectCard = (card, cardName) => {
    setSelectedCard(card);
    setCookie('selectedCard', cardName, { path: '/' });
    navigate('/home');
  };

  return (
    // <div className="container-login">
    //   <div className="row">
    //     <div class="label-container"> 
    //       <label class="empresa-label">Seleccione empresa</label>
    //     </div>
    //     <div className={`col-md-6 ${selectedCard === 1 ? "selected" : ""}`}>
    //     <div className="card" onClick={() => handleSelectCard(1, 'Dafa')}>
    //       <img src="/img/dafaLogo.png" className="card-img-top" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
    //         <div className="card-body">
    //           <h5 className="card-title">Dafa</h5>
    //           <p className="card-text">Facility services</p>
    //           <button className="btn btn-primary">Seleccionar</button>
    //         </div>
    //       </div>
    //     </div>
    //     <div className={`col-md-6 ${selectedCard === 2 ? "selected" : ""}`}>
    //     <div className="card" onClick={() => handleSelectCard(2, 'Fugar')}>
    //         <img src="/img/fugarLogo.png" className="card-img-top" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
    //         <div className="card-body">
    //           <h5 className="card-title">Fugar</h5>
    //           <p className="card-text">Servicios industriales</p>
    //           <button className="btn btn-primary">Seleccionar</button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="container-fluid vh-100 d-flex justify-content-center align-items-center">
    <div className="col-md-10">
      <table className="table table-striped table-hover table-bordered text-center">
        <thead className="thead-light">
          <tr>
            <th>RUT</th>
            <th>Empresa</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>77.040.719-2</td>
            <td>FUGAR SERVICIOS INDUSTRIALES SPA</td>
            <td><a href=''onClick={() => handleSelectCard(2, 'Fugar')}>Entrar</a></td>
          </tr>
          <tr>
            <td>77.683.284-7</td>
            <td>COMERCIALIZADORA NIDI PACK SPA</td>
            <td><a href="#">Entrar</a></td>
          </tr>
          <tr>
            <td>77.543.603-4</td>
            <td>INVERSIONES DAFA SPA</td>
            <td><a href="#" >Entrar</a></td>
          </tr>
          <tr>
            <td>77.871.618-6</td>
            <td>DAFA FACILITY SERVICES SPA</td>
            <td><a href="" onClick={() => handleSelectCard(1, 'Dafa')}>Entrar</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  );
}

export default App;