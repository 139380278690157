import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ValidatingLogin from './Validations/LoginValidation';
import Axios from 'axios';

function Login() {
    const [values, setValues] = useState({
        usuario: '',
        password: ''
    })
    const navigate = useNavigate();
    const [errors, setErrors] = useState({})
    const handleInput = (event) => {
        setValues(prev => ({...prev, [event.target.name]: [event.target.value]}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setErrors(ValidatingLogin(values));
        if(errors.usuario === "" && errors.password === ""){
            Axios.post('http://localhost:3001/login', values)
            .then(res => {
                if(res.data === "Succes"){
                    navigate('/prehome');
                } else {
                    alert("Usuario no existe");
                    console.log(res);
                }
            })
            
            .catch(err => console.log(err));
        }
    }

    return (
        <div className='container-login'>
            <div className='form-container'>
                <form action="" onSubmit={handleSubmit}>
                    <div className='mb-3'>
                    <img src="/img/IDAFA.png" alt="logo" style={{ maxWidth: "100%", height: "auto", width: "500px" }} />
                    </div> 
                    <div className='mb-3'>
                        <label htmlFor='usuario'>Usuario</label>
                        <input name='usuario' placeholder='Ingrese nombre de usuario' className='form-control' onChange={handleInput}></input>
                        {errors.usuario && <span className='text-danger'>{errors.usuario}</span>}
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='password'>Contraseña</label>
                        <input name='password' placeholder='Ingrese contraseña' type='password' className='form-control' onChange={handleInput}></input>
                        {errors.password && <span className='text-danger'>{errors.password}</span>}
                    </div>
                    <button type='submit' className='button-login w-100'>Entrar</button>
                    {/* <Link to="/Registro" className='btn btn-default border w-100 bg-light w-100 text-decoration-none'>Registrar</Link> */}
                </form>
            </div>
        </div>
    )
}

export default Login
