import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import './ccss/sb-admin-2.min.css';
import './ccss/fontawesome-free/css/all.min.css'

const cardImages = {
  Dafa: "/img/dafaLogo.png",
  Fugar: "/img/fugarLogo.png"
};

const DestinationPage = () => {
  const [cookies] = useCookies(['selectedCard']);
  const imageUrl = cardImages[cookies.selectedCard]
  const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
  const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
  const [isInventario, setisIventatioCollapsed] = useState(true);
  const [isPrevencion, setisPrevencionCollapsed] = useState(true);
  const [isLogistica, setisLogisticaCollapsed] = useState(true);

  const toggleCollapse = (collapseSetter) => {
    collapseSetter(prevState => !prevState);
  };

  return (
    <div id="page-top">
      <div id="wrapper">
        {/* Sidebar */}
        <ul class="navbar-nav bg-gradient-primary sidebar accordion sidebar-dark" id="accordionSidebar">
          <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
            <Link className="nav-link" to="/Prehome">
              <img src={imageUrl} alt="Selected Card" style={{ width: '220px', height: 'auto' }} />
            </Link>
          </a>
          <hr className="sidebar-divider my-0" />
          <li className="nav-item">
            <a className="nav-link" onClick={() => toggleCollapse(setIsComponentsCollapsed)}>
              <i class="fas fa-users"></i>
              <span>Recursos Humanos</span>
            </a>
            <div className={`collapse ${isComponentsCollapsed ? '' : 'show'}`} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Seleccione:</h6>
                <Link className="collapse-item" to="/buttons"><i className="fas fa-file-signature"></i> Contratos</Link>
                <Link className="collapse-item" to="/cards"><i class="fas fa-file-invoice-dollar"></i>  Finiquitos</Link>
                <Link className="collapse-item" to="/cards"><i class="fas fa-indent"></i> Anexos</Link>
                <Link className="collapse-item" to="/cards"><i class="fas fa-file-word"></i> Pacto H.H</Link>
                <Link className="collapse-item" to="/cards"><i class="fas fa-file-word"></i>  Asistencia</Link>
                <Link className="collapse-item" to="/Trabajadores"><i class="fas fa-file-word"></i>  Trabajadores</Link>
              </div>
            </div>
          </li>
          <hr className="sidebar-divider my-0" />
          <li className="nav-item">
            <a className="nav-link" onClick={() => toggleCollapse(setIsUtilitiesCollapsed)}>
              <i className="fas fa-fw fa-wrench"></i>
              <span>Contabilidad</span>
            </a>
            <div className={`collapse ${isUtilitiesCollapsed ? '' : 'show'}`} aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Aqui opciones:</h6>
                <Link className="collapse-item" to="/colors">Opcion1</Link>
                <Link className="collapse-item" to="/borders">Opcion2</Link>
                <Link className="collapse-item" to="/animations">Opcion3</Link>
                <Link className="collapse-item" to="/other">Opcion4</Link>
              </div>
            </div>
          </li>
          <hr className="sidebar-divider my-0" />
          <li className="nav-item">
            <a className="nav-link" onClick={() => toggleCollapse(setisIventatioCollapsed)}>
              <i class="fas fa-boxes"></i>
              <span>Inventario</span>
            </a>
            <div className={`collapse ${isInventario ? '' : 'show'}`} aria-labelledby="headingUtilitie" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Aqui opciones:</h6>
                <Link className="collapse-item" to="/colors">Opcion1</Link>
                <Link className="collapse-item" to="/borders">Opcion2</Link>
                <Link className="collapse-item" to="/animations">Opcion3</Link>
                <Link className="collapse-item" to="/other">Opcion4</Link>
              </div>
            </div>
          </li>
          <hr className="sidebar-divider my-0" />
          <li className="nav-item">
            <a className="nav-link" onClick={() => toggleCollapse(setisPrevencionCollapsed)}>
              <i class="fas fa-hard-hat"></i>
              <span>Prevención</span>
            </a>
            <div className={`collapse ${isPrevencion ? '' : 'show'}`} aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Aqui opciones:</h6>
                <Link className="collapse-item" to="/colors">Opcion1</Link>
                <Link className="collapse-item" to="/borders">Opcion2</Link>
                <Link className="collapse-item" to="/animations">Opcion3</Link>
                <Link className="collapse-item" to="/other">Opcion4</Link>
              </div>
            </div>
          </li>
          <hr className="sidebar-divider my-0" />
          <li className="nav-item">
            <a className="nav-link" onClick={() => toggleCollapse(setisLogisticaCollapsed)}>
              <i class="fas fa-truck-loading"></i>
              <span>Logística</span>
            </a>
            <div className={`collapse ${isLogistica ? '' : 'show'}`} aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Aqui opciones:</h6>
                <Link className="collapse-item" to="/colors">Opcion1</Link>
                <Link className="collapse-item" to="/borders">Opcion2</Link>
                <Link className="collapse-item" to="/animations">Opcion3</Link>
                <Link className="collapse-item" to="/other">Opcion4</Link>
              </div>
            </div>
          </li>
        </ul>
        {/* End of Sidebar */}

        {/* Content Wrapper */}
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            {/* Topbar */}


          </div>
          {/* Footer */}
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
            </div>
          </footer>
        </div>
        {/* End of Content Wrapper */}
      </div>
      {/* End of Page Wrapper */}
    </div>
  );
}

export default DestinationPage;